import React from 'react';

// Components
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';
import classnames from 'classnames';

const layoutClassName = "max-w-3xl mx-auto px-6";

export function CaseCard({node}){
  const image = getImage(node.frontmatter.frontImg)
  return <Link className="bg-slate-100 rounded-md overflow-hidden hover:opacity-80 transition-opacity" to={node.fields.path}>
          <GatsbyImage image={image} alt={node.frontmatter.title} imgClassName="rounded-t-md" className="aspect-video"/>
          <div className=" p-3 lg:p-6">
            <h2 className="font-medium text-xl">{node.frontmatter.title}</h2>
            <p className="text-slate-500 mt-1">{node.excerpt}</p>
          </div>
        </Link>
}


export function CasesList({values}){
  return <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-auto max-w-7xl px-6 lg:px-3 gap-6">
          {values.map((d,i)=><CaseCard key={i} {...d}/>)}
         </div>
}

export function SmallCaseCard({node}){
  const image = getImage(node.frontmatter.frontImg)
  return <Link className="bg-white rounded-md shadow overflow-hidden hover:opacity-80 transition-opacity" to={node.fields.path}>
          <GatsbyImage image={image} alt={node.frontmatter.title} imgClassName="rounded-t-md" className="aspect-video"/>
          <div className=" p-3 lg:p-6">
            <h2 className="font-medium text-xl">{node.frontmatter.title}</h2>
            <p className="text-slate-500 mt-1">{node.excerpt}</p>
          </div>
        </Link>
}

export function MoreArticles({previous, next}){
  const { t } = useTranslation("case-study");
  return <div className={classnames("bg-slate-50 py-12")}>  
           <div className={classnames(layoutClassName, "bg-slate-50")}>  
            <h2 className="text-3xl font-serif font-bold text-gray-700 leading-[1.6]">{t("more.title")}</h2>
            <div className=" grid md:grid-cols-2 gap-3">
              {previous && <SmallCaseCard node={previous}/>}
              {next && <SmallCaseCard node={next}/>}
            </div>
          </div>
        </div>
}
